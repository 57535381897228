// **  Initial State
const initialState = {};

const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ITEMS_BALANCE":
      return {
        ...state,
        itemsBalance: action.data && action.data.map((el) => +el),
      };
    case "RUST_TABLE_GET":
      return { ...state, rustTable: action.payload };

    case "GET_MARKET_ITEMS":
      return {
        ...state,
        marketItems:
          action.data &&
          action.data.allowed &&
          action.data.allowed.map((el) => +el),
        blockedMarket:
          action.data &&
          action.data.denied &&
          action.data.denied.map((el) => +el),
      };
    case "GET_STEAM_BOT_ITEMS":
      return {
        ...state,
        steamBotItems: action.data,
      };
    case "GET_ALL_USERS_ITEMS":
      return { ...state, allUsersItems: action.data.data };

    case "UPDATE_ITEM":
      return { ...state, rustTable: action.callBackData };

    case "GET_INVENTORIES_TOTAL":
      return { ...state, inventsTotal: action.data };

    default:
      return state;
  }
};

export default itemsReducer;
