// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import dataReducer from './dataReducer';
import chatReducer from './chatReducer';
import betsReducer from './betsReducer';
import itemsReducer from './itemsReducer';
import translationsReducer from './translationsReducer';
import ticketsReducer from './ticketsReducer';
import rolesReducer from './rolesReducer';

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  data: dataReducer,
  chats: chatReducer,
  bets: betsReducer,
  items: itemsReducer,
  translations: translationsReducer,
  tickets: ticketsReducer,
  roles: rolesReducer
})

export default rootReducer
